<template>

  <Section>
    <Container>

      <FadeIn class="mx-auto max-w-3xl divide-y divide-[#E4E2DF]">
        <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{{ content.title }}</h2>
        <div class="mt-10 space-y-6 divide-y divide-[#E4E2DF]">
          <div v-for="(faq, index) in content.faqs" :key="faq.question" class="pt-6">
            <div @click="togglePanel(index)" class="pointer">
              <div class="flex w-full items-start justify-between text-left">
                <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
                <span class="ml-6 flex h-7 items-center">
                  <Icon name="iconoir:plus" v-if="activePanel !== index" class="h-6 w-6" />
                  <Icon name="iconoir:minus" v-else class="h-6 w-6" />
                </span>
              </div>
            </div>
            <div v-show="activePanel === index" class="mt-2">
              <p class="text-base leading-7 text-gray-600 faq__a" v-interpolation v-html="faq.answer"></p>
            </div>
          </div>
        </div>
      </FadeIn>

    </Container>
  </Section>
</template>

<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  }
})

const activePanel = ref(999)

const togglePanel = index => {
  const activeIndex = activePanel.value
  if (index === activeIndex) {
    activePanel.value = 999
  } else {
    activePanel.value = index
  }
}


</script>